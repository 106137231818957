import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Seo from "../components/seo"

import NewsCard from "../components/NewsCard"
import NewNewsCard from "../components/NewNewsCard"
import TwitterCard from "../components/TwitterCard"

import { OutboundLink } from "gatsby-plugin-google-analytics"

const GET_RECENT_POSTS = graphql`
  {
    allWpArticle(
      sort: { order: DESC, fields: date }
      filter: { articleDetails: { pinArticle: { eq: true } } }
      limit: 3
    ) {
      edges {
        node {
          articleDetails {
            articleExcerpt
            originalContent
            outsideArticleLink {
              url
            }
            pinArticle
          }
          title
          featuredImage {
            node {
              mediaItemUrl
              date
            }
          }
          content
          slug
        }
      }
    }
  }
`
//allTwitterStatusesUserTimelineGood4UTweets(limit: 3) {
//  edges {
//    node {
//      created_at
//      favorite_count
//      entities {
//        hashtags {
//          text
//        }
//        urls {
//          display_url
//          expanded_url
//        }
//        media {
//          media_url
//        }
//      }
//      favorited
//      full_text
//      id_str
//      id
//      user {
//        name
//        screen_name
//        profile_image_url_https
//      }
//      retweet_count
//    }
//  }
//}
const IndexPage = () => {
  const data = useStaticQuery(GET_RECENT_POSTS)
  //const tweets = data.allTwitterStatusesUserTimelineGood4UTweets.edges
  const { edges } = data.allWpArticle

  // console.log(tweet1)
  return (
    <section id="home">
      <Seo title="Home" />
      <div className="one">
        <div class="wrapper">
          <p>Good4</p>
          <div class="words">
            <span>neighborhoods</span>
            <span>students</span>
            <span>teachers</span>
            <span>communities</span>
            <span>economies</span>
            <span>u</span>
            <span>neighborhoods</span>
          </div>
        </div>
      </div>
      <div className="two">
        <div className="container">
          <div className="content">
            <p className="big-text">One size doesn't fit all in education</p>
            <p>Especially for students of color and those living in poverty.</p>
          </div>
        </div>
      </div>
      <div className="three big-text-center">
        <div className="container">
          <div className="content">
            <p>
              Why should we care that all students have equal opportunity for a
              quality education?
            </p>
            <p>
              Because it is <strong>Good.</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="four split-half-circle-right">
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="wrap-content">
                <p className="big-text">
                  It’s not only the right thing to do, but it makes good sense.
                </p>
              </div>
            </div>
            <div className="right">
              <div className="circle-pic"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="five">
        <div className="container">
          <h2>Featured News</h2>
          <div className="middle">
            {edges.map((post, index) => {
              return <NewNewsCard ribbon={false} data={post.node} key={index} />
            })}
          </div>
          <Link className="btn-wrap" to="/news">
            <div className="btn btn-yellow bottom-btn">See More</div>
          </Link>
        </div>
      </div>
      <div className="six">
        <div className="container">
          

        </div>
      </div>
    </section>
  )
}

export default IndexPage
